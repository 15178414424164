import Vue from 'vue';

import "../plugins/config.js";
import "../plugins/service/index.js";

// import {getLCP, getFID, getCLS} from 'web-vitals';

Vue.prototype.$sac = new Vue ({
    data() {
        return {
            lib: 'imply-desk',
            version: 'v1.2.1',
            recaptchaAtivo: false,
            el: '',
            language: 'pt',
        }
    },
    methods: {

        init() { // TODO: init

            // =============================================================================

            var sacImport = document.createElement('script');
            sacImport.type = 'text/javascript';
            sacImport.async = true;
            sacImport.src = 'https://sdk.imply.com/' + this.lib + '-' + this.version + '.min.js';

            localStorage.setItem("sdkSacVersion", this.version);
            
            // =============================================================================

            //if(location.href.indexOf('preprodca2021') != -1 ){

                // REDUZ O TEMPO DE CARREGAMENTO PARA QUE OS ROBOS DO GOOGLE NÃO FIQUEM APONTANDO CARREGAMENTO
                setTimeout(function () {
                    document.body.insertBefore(sacImport, document.body.childNodes[document.body.childNodes.length - 1].nextSibling);
                    // document.body.insertBefore(botao, document.body.childNodes[document.body.childNodes.length - 1].nextSibling);
                }, 2000);

            //}   
            
        },

        startFlowSac() { // TODO: startFlowSac

            let idprojeto = this.$config.dados.SAC.idProjeto;
            /*
            * lang = def pt, es, en
            * endpoint = http://127.0.0.1:8080 ou externo
            * idprojeto = idprojeto local ou da api
            * corPrimaria = blue, #000000
            * sitekeyRecaptcha = site key do projeto local
            * nome: nome da pessoa logada
            * email: da pessoa logada
            * */
            this.recaptchaAtivo = true;

            var pessoa = this.$axios.getLocal('session');
    
            var params = {
                sitekeyRecaptcha: this.$config.conf.site_key_recaptcha,
                idprojeto: idprojeto,
                lang: this.language,
                corPrimaria: this.$config.dados.corprimaria,
                nome: pessoa ? pessoa.data.nome : "",
                email: pessoa ? pessoa.data.email : "",
            }
    
            if(this.$config.dados.SAC.status) {
                var sac = new imply.SAC(params);
                this.el = document.querySelector('.sac-imply-button-close');
            }

        },
        revert() { // TODO: revert
            this.recaptchaAtivo = false;
        }
    },
    watch: {
        el() {
            this.el.addEventListener("click", () => {
                this.el.addEventListener("click", this.revert(), false);
            }, false);
        },        
    }
});

Vue.prototype.$sac.init();
import Vue from 'vue'
import App from '@/App.vue'
import initRouter from '@/plugins/router/index.js'
import vuetify from '@/plugins/vuetify'

// TODO: FILTROS
import "animate.css"
import _ from 'lodash';
import '@/plugins/filtros';

// TODO: CONTROLLERS
import '@/controllers/Usuario.controller.js';
import '@/controllers/Carrinho.controller.js';
import '@/controllers/Google.controller';
import '@/controllers/Facebook.controller';
import '@/controllers/Service.controller';
import '@/controllers/Util.controller';
import '@/controllers/Storage.controller';
import '@/controllers/SuperIngresso.controller';
import '@/controllers/Sac.controller';


// TODO: PLUGINS
import '@/plugins/config';
import '@/plugins/languagen';
import '@/plugins/service';

// TODO: REGRA DE NEGOCIO
import '@/controllers/RegradeNegocio.controller';


Vue.config.productionTip = true

Vue.prototype.$config.getConfig()
.then(response => {
    
    Vue.prototype.$config.dados = response || {};
    Vue.prototype.$config.conf = response || {};
    window.$config = response || {};
    const router = initRouter();
    window.$router = router;
    Vue.prototype.$google.init();


    const styles = `
        @import url('${window.$config.fonteImport}');
        :root {
            --dl-color-principal: ${window.$config.corprimaria} !important;
            --dl-color-secundaria: ${window.$config.corsecundaria} !important;
            --dl-color-terciaria: ${window.$config.corterciaria} !important;
            --dl-color-quaternaria: ${window.$config.corquaternaria} !important;
            --dl-color-primary-100: ${window.$config.corprimaria};
            --dl-color-primary-300: ${window.$config.corprimaria};
            --dl-color-primary-500: ${window.$config.corprimaria};
            --dl-color-primary-700: ${window.$config.corprimaria};
        }

        .primary--text {
            color: var(--dl-color-principal) !important;
        }

        .primary {
            color: var(--dl-color-principal) !important;
        }

        .bg-primary {
            background-color: var(--dl-color-principal) !important;
        }
        
        * { box-sizing: border-box;
            border-width: 0;
            border-style: solid;
            font-size: 15px;
            font-family: 'Poppins','sans-serif' !important;
            line-height: 1.6em;
        }

        html, body { 
            font-family: ${window.$config.fonteName} !important;
        }

        h1, h2, h3 { 
            /* border-bottom: 3px solid ${window.$config.corsecundaria} !important; */
            color: ${window.$config.corprimaria}; 
            margin-bottom: 16px; 
            text-transform: uppercase; 
            font-weight: bold;
        }

        ::-webkit-scrollbar {
            width: 6px;
            height: 8px;
        }

        ::-webkit-scrollbar-track {
            background-color: ${window.$config.corsecundaria} !important;
        }
        
        ::-webkit-scrollbar-thumb {
            background: ${window.$config.corprimaria} !important;
            border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb:hover {
            background: ${window.$config.corterciaria} !important;
        }
        
        .BotaoGradiente {
            border: solid 1px black;
            cursor: pointer;
            background: ${window.$config.corprimaria} !important;
        }

        .BotaoGradiente:hover {
            background: ${window.$config.corsecundaria} !important;
        }

        .BotaoGradiente:active {
            background:  ${window.$config.corprimaria}
        }

        .card-pedidos-titles {
            color: ${window.$config.corprimaria} !important;
        }
    `

    window.util.createdElement('style', { type: 'text/css' }, styles, 'head');
    window.util.createdElement('link', { rel: 'icon', href: window.$config.favicon,  type: "image/gif", sizes: "16x16" }, "", 'head');
    window.util.createdElement('title', {}, window.$config.titulo, 'head');
    window.util.createdElement('meta', { name:"theme-color", content:"#1C95B7" }, "", 'head');
    window.util.createdElement('meta', { name:"apple-mobile-web-app-status-bar-style", content:"#1C95B7" }, "", 'head');
    window.util.createdElement('meta', { name:"msapplication-navbutton-color", content:"#1C95B7" }, "", 'head');
    window.util.createdElement('meta', { name:"msapplication-TileColor", content:"#1C95B7" }, "", 'head');

    window.util.createdElement('meta', { name:"description", content: window.$config.description }, "", 'head');
    window.util.createdElement('meta', { name:"keywords", content: window.$config.keywords }, "", 'head');
    window.util.createdElement('meta', { name:"og:url", content: location.origin }, "", 'head');
    window.util.createdElement('meta', { name:"og:site_name", content: window.$config.titulo }, "", 'head');
    window.util.createdElement('meta', { name:"og:title", content: window.$config.titulo }, "", 'head');
    window.util.createdElement('meta', { name:"og:description", content: window.$config.description }, "", 'head');
    window.util.createdElement('meta', { name:"og:image", content: window.$config.logowhite }, "", 'head');
    window.util.createdElement('meta', { name:"copyright", content: "&copy; " + window.$config.titulo }, "", 'head');


    new Vue({
        render: h => h(App),
        vuetify,
        router,
        methods: {
            onCaptchaVerified(){
                console.log('onCaptchaVerified')
                if(window.$config.recaptcha == "invisible"){
                    window.grecaptcha.execute()
                }
            },
            onCaptchaExpired(){
                window.grecaptcha.reset()
                console.warn('onCaptchaExpired')
                if(window.$config.recaptcha == "invisible"){
                    const time = setInterval(() => {
                        if(window.grecaptcha){
                            clearInterval(time)
                            window.grecaptcha.execute()
                        }
                    }, 1000)
                }
            },
        }
    }).$mount('#app')
    
})

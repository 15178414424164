import Vue from 'vue'
Vue.prototype.$facebook = new Vue ({
    data() {
        return { }
    },
    methods: {

        init(){
            if(window.fbq){
                window.fbq('track', 'PageView');
            }
        },

        pesquisa(search){
            if(window.fbq){
                window.fbq('track', 'Search', {
                    search_string: search
                });
            }
        },

        visualizacao(valor){
            if(window.fbq){
                window.fbq('track', 'ViewContent', {
                    value: valor,
                    currency: 'BRL'
                });
            }
        },

        addCarrinho(item){
            if(window.fbq){
                window.fbq('track', 'AddToCart', {
                    content_ids: [item.idproduto],
                    content_type: item.dscproduto,
                    value: item.preco,
                    currency: 'BRL'
                });
            }
        },

        listaDesejo(item){
            if(window.fbq){
                window.fbq('track', 'AddToWishlist', {
                    content_ids: [item.idproduto],
                    content_type: item.dscproduto,
                    value: item.preco,
                    currency: 'BRL'
                });
            }
        },

        checkout(){
            if(window.fbq){
                window.fbq('track', 'InitiateCheckout');
            }
        },

        addInfoPgto(){
            if(window.fbq){
                window.fbq('track', 'AddPaymentInfo');
            }
        },

        concluirCompra(resumo){
            if(window.fbq){
                window.fbq('track', 'Purchase', {
                    content_ids: resumo.idprodutos,
                    content_type: 'Tickets',
                    value: resumo.valor,
                    currency: 'BRL'
                });
            }
        },

        cadastro(item){
            if(window.fbq){
                window.fbq('track', 'Lead', {
                    content_name: item.nome,
                    content_category: item.categoria,
                    value: item.preco,
                    currency: 'BRL'
                });
            }
        },

        completarCadastro(){
            if(window.fbq){
                window.fbq('track', 'CompleteRegistration');
            }
        },

        eventoPersonalizado(evento, options = { name: 'Cartão de Crédito', value: 0 }){
            if(window.fbq){
                window.fbq('track', evento, {
                    name: options.name,
                    value: options.value,
                    currency: 'BRL'
                });
            }
        }
    }
});

setTimeout(() => {
    Vue.prototype.$facebook.init()
}, 1500)


const TITTLE = ""
let prefix = '/'


const url = location.href

// A VARIAVEL DO LINK DE POLITICAS ESTA VINDO DO TAG MANAGER COMO 'pt/politicas/cookies'
//ESSA VERIFICAÇÃO FAZ ALTERNAR AS ROTAS DO LINK DE ACORDO COM O CLICK E A LINGUAGEM
if(url.includes("/en/product/pt/politicas/cookies")|| 
url.includes("/en/policies/pt/politicas/cookies")|| 
url.includes("/en/product/es/politicas/cookies")){
    const newUrl = `/en/policies/cookies`
    window.location.replace(`${newUrl}`)
} 
export default [
    

    {
        path: '/',
        name: 'Home',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "home" */ '@/views/home.vue'),
        meta: { title: TITTLE + 'Home', requiresAuth: false }
    },

    {
        path: prefix + 'profile',
        name: 'Perfil',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "profile" */ '@/views/perfil.vue'),
        meta: { title: TITTLE + 'Profile', requiresAuth: false }
    },

    {
        path: prefix + 'checkout',
        name: 'Checkout',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "checkout" */ '@/views/checkout.vue'),
        meta: { title: TITTLE + 'Checkout', requiresAuth: false }
    },

    {
        path: prefix + 'information/:pagina/',
        name: 'Informacoes',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "information" */ '@/views/informacoes.vue'),
        meta: { title: TITTLE + 'Information', requiresAuth: false }
    },

    {
        path: prefix + 'policies/:pagina/',
        name: 'Politicas',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "policies" */ '@/views/politicas.vue'),
        meta: { title: TITTLE + 'Policies', requiresAuth: false }
    },

    {
        path: prefix + 'requests',
        name: 'MeusPedidos',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "requests" */ '@/views/meus-pedidos.vue'),
        meta: { title: TITTLE + 'Requests', requiresAuth: false }
    },

    {
        path: prefix + 'product/:id',
        name: 'Produto',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "product" */ '@/views/pagina-produtos.vue'),
        meta: { title: TITTLE + 'Product', requiresAuth: false }
    },

    {
        path: prefix + 'resetpassword',
        name: 'Senha',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "resetpassword" */ '@/views/recuperacao.vue'),
        meta: {title: TITTLE + 'Reset password', requiresAuth: false}
    },

    {
        path: prefix + 'validatemail',
        name: 'Confirmar',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "validatemail" */ '@/views/confirm-email.vue'),
        meta: {title: TITTLE + 'E-mail confirm', requiresAuth: false}
    },

    {
        path: prefix + 'voucher',
        name: 'Voucher',
        props: true,
        params: true,
        component: () => import(/* webpackChunkName: "voucher" */ '@/views/voucher.vue'),
        meta: {title: TITTLE + 'Voucher', requiresAuth: false}
    },

    {
        path: '*',
        name: 'Error',
        component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue'),
        meta: { title: TITTLE + 'Error 404', requiresAuth: false }
    }
]


import { endpoints, regrasDoNegocio } from '../../../package.json'

let endpoint = ''
switch (process.env.NODE_ENV) {
    case 'development':
        endpoint = endpoints.homologacao
        break
    case 'production':
        endpoint = endpoints.producao
        break
}

const ID_LOGIN_SI = regrasDoNegocio.idLoginSI;
const BASE_URL = endpoint;
const ORIGEM = window.location.origin;

const ENDPOINTS = {

    GETLOGINS                 : 'logins?url='+ ORIGEM,
    LOGIN                     : 'login/',
    GETTERMOCOMPRA            : 'pessoa/cadastro/termo?tipo=2',

    DADOSCADASTRAIS           : 'pessoa/cadastro/dados',
    TROCARSENHA               : 'pessoa/cadastro/trocar/senha',
    RECUPERARSENHA            : 'pessoa/cadastro/recuperar/senha',
    RECUPERARSENHANOVASENHA   : 'pessoa/cadastro/recuperar/nova',
    CONFIRMAREMAIL            : 'pessoa/cadastro/confirmar/email',

    GETCONTEUDOS              : 'cms/conteudos/',
    GETCONTEUDO               : 'cms/conteudo/',

    GETSACPERGUNTAS           : 'SAC/perguntas-frequentes',
    CADASTROPESSOA            : 'cadastro/' + ID_LOGIN_SI, // ID do login do SI foi fixado.

    CONFIGS                   : 'CustomUserApps/Configs',
    SETCONTATO                : 'CustomUserApps/Contato',
    NEWSLETTER                : 'CustomUserApps/Newsletter',
    VALIDAREMAIL              : 'CustomUserApps/ValidaEmail',
    DASHBOARD                 : 'CustomUserApps/dashboard',
    LOGINDASHBOARD            : 'CustomUserApps/login-dashboard',
    SETAGENDAMENTO            : 'CustomUserApps/InsereRioBoi',
    GETAGENDAMENTO            : 'CustomUserApps/DiasCompra',
    GETQTD                    : 'CustomUserApps/ConsultaRioBoi',
    PEDIDOSPAGINADOS          : 'CustomUserApps/PedidosPaginado',

    // SUPERINGRESSO

    GETEVENTOS                : 'getEventos',
    GETAPRESENTACOES          : 'getApresentacoes',
    GETAPRESENTACAO           : 'getApresentacao',
    GETPRODUTOS               : 'getProdutos',   
    SETCARRINHO               : 'iniciarReserva',
    GETCARRINHOPAGE           : 'getCarrinhoPage',
    GETCARRINHOPAGEITENS      : 'getCarrinhoPageItens',
    CANCELARRESERVA           : 'cancelarReserva',
    CANCELACOMPRA             : 'cancelaCompra',
    GETIMPRESSAO              : 'getImpressao',
    SETPORTADORITEM           : 'portadorSetItem',
    INICIARTROCAVOUCHER       : 'iniciarTrocaVoucher',
    GETGATEWAYPAGAMENTO       : 'getGatewayPagamento',
    VALIDARENDERECO           : 'TransportadoraValidaEnderecoEntrega/',

}

export { ENDPOINTS, BASE_URL, ID_LOGIN_SI }
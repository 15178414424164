import Vue from 'vue';
import VueRouter from 'vue-router';

import '@/plugins/languagen';
import pt from '@/plugins/router/pt.js';
import es from '@/plugins/router/es.js';
import en from '@/plugins/router/en.js';

// PROPRIEDADES

let routes = null;
let router = pt;
let base   = '/';

function initRouter() {

    if(window.$config && window.$config.multilinguagem){
        switch(Vue.prototype.$lang_.getLang().title){
            case 'pt':
                routes = pt;
                break;
            case 'es':
                routes = es;
                break;
            case 'en':
                routes = en;
                break;
        }
    
        base = Vue.prototype.$lang_.getLang().title + '/';
    
    } else {
        routes = pt
    }

    return exit();
}

function exit() {

    const config = {
        base: base, 
        mode: 'history',
        routes
    }

    router = new VueRouter(config)

    router.beforeEach((to, from, next) => {
        
        next();

        document.title = to.meta.title;
        
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })

    })

    Vue.use(VueRouter)

    return router

}


export default initRouter

import StorageTable from "@/controllers/Storage.controller.js"
import Service from "@/controllers/Service.controller.js"
import Vue from 'vue'
export default class Util extends StorageTable { // TYPE: Class: Util()
    constructor(){
        super()
        this.width = window.screen.width
        this.modal = {
            show: false,
            titulo: '',
            conteudo: ''
        }
        this.$root = false
    }

    async getVue() {
        if(this.$root){
            return this.$root
        }
        await this.sleep(1)
        this.$root = document.querySelector('#app').__vue__.$root
        return this.$root
    }

    isNavigator(){ // FUNC: isNavegador()
        // CC: detecta qual o navegador está usando

        let navegador = ''
        let navegadores = [
            { name: "os/", value: "Opera" },
            { name: "msie/", value: "Edge" },
            { name: "firefox/", value: "Mozilla Firefox" },
            { name: "epiphany/", value: "Epiphany" },
            { name: "chrome/", value: "Google Chrome" },
            { name: "safari/", value: "Safari" }
        ]

        const getBrowser = (item) => {
            if(navigator.userAgent.toLowerCase().indexOf(item.name) != -1) {
                return item.value
            }
        }

        navegadores.every((item) => {
            if(navegador = getBrowser(item)) return false
            return true
        })

        return navegador
    }

    async go(name, params = {}, query = {}) { //FUNC: go(name, params = {}, query = {})
        // CC: Usa o Vue router para mudar de página
        const root = await this.getVue()
        root.menumobile = false

        let instance  = await this.getVue()
        let route      = instance.$route
        let router     = instance.$router

        if(typeof name == 'object'){
            if(route.params.pagina != name.params.pagina ){
                router.push(name);
                this.scroll()
            }
            return
        }

        if(name.length == 0) {
            console.warn('Não existe link por favor verificar!')
            return false
        }

        if(name.indexOf('http') != -1) {
            location.href = name
            return false
        }
        if(name == 'sair'){
            this.destroyLocal()
            return
        }
        if(name == 'sac'){
            instance.$sac.startFlowSac()
            return
        }
        if(name == 'ModalTrocaSenha'){
            this.$root.modalTrocaSenha.show = !this.$root.modalTrocaSenha.show
            return
        }
        if(route.name != name ){
            router.push( { name: name, params: params, query: query } );
            this.scroll()
        }
        return true
    }

    async sleep(segundos) { //FUNC: sleep(segundos)
        // CC: Pausa "segundos" a leiturta asynconica do js.
        return new Promise(resolve => setTimeout(resolve, segundos * 1000))
    }

    scroll(id){ // FUNC: scroll()
        // CC: ScrollSmooting
        if(id){
            document.getElementById(id).scrollIntoView(true)
            if(window.grecaptcha) window.grecaptcha.reset();
            return
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    clone(object){ //FUNC: clone(object)
        // CC: Clona o objeto e as alterações não afeta o objeto original.
        return Object.assign({}, object)
    }

    order(object, key){ // FUNC: order(object or array)
        // CC: Ordena o array ou objeto crescente.
        if(key) {
            object.sort(function (a, b) {
                if(a[key] > b[key]) {
                    return 1;
                }
                if(a[key] < b[key]) {
                    return -1;
                }
                return 0;
            });
        }

        object.sort(function (a, b) {
            if(a > b) {
                return 1;
            }
            if(a < b) {
                return -1;
            }
            return 0;
        });

        return object

    }

    addGetInfoUrl(object) { // FUNC: addGetInfoUrl()
        let url = window.location.href.split("#");

        let urlcomplete = url[0];
        let params = urlcomplete.indexOf('?') > -1 ? "&" : "?";

        for (let i in object) {
            params = params + i + "=" + object[i]
        }

        urlcomplete = urlcomplete + params;
        return urlcomplete;
    }

    tratarLink(str) { // FUNC: tratarLink
        // CC: Remove os acentos, e substitue espaços por hífem
        str = str.replace(/ /g, "-")
        str = str.replace(/---/g, "-");
        str = str.replace(/"/g, "");
        str = str.replace(/'/g, "");
        str = this.replaceSpecialChars(str)
        return str.toLowerCase();

    }

    replaceSpecialChars(str){ // FUNC: replaceSpecialChars(str)
        // CC: Remove os acentos, aspas e caracteres especiais.
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }

    removerCaracteres(caracteres) { // FUNC: removerCaracteres(caracteres)
        // CC: Retorna apenas números.
        return Number(caracteres.toString().replace(/\D+/g, ''));
    }

    removeHTML(str){ // FUNC: removeHTML(str)
        // CC: Remove as tags HTML
        if(str && str.includes("<", ">")){
            str = str.replace(/<.*?>/g,"");
            str = str.replace(/&nbsp;/g,"");    
            return str.replace(/\n/g,"").trim();
        }
        return str;
    }

    asMoney(string) { // FUNC: asMoney(string)
        // CC: Converte em moeda brasileira com sifrão e centavos.
        if(!window.Intl) return 'R$ ' + parseFloat(string).toFixed(2)
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
        return formatter.format(string)
    }

    asDate(data) { // FUNC: asDate(data)
        // CC: Retorna a data formatava para xx/xx/xxxx
        data = data.split(' ')[0]
        data = data.split('/')
        const dateObject = new Date(data[2], data[1]-1, data[0])
        if(this.getLocal('lang')){
            return `${dateObject.toLocaleDateString(this.getLocal('lang'))}`
        }
        return `${dateObject.toLocaleDateString("pt-BR")}`
    }

    asDateHour(data) { // FUNC: asDateHour(data)
        // CC: Retorna a data e hora formatava para xx/xx/xxxx 00:00:00
        if(this.getLocal('lang')){
            return `${new Date(data).toLocaleDateString(this.getLocal('lang'))} ${new Date(data).toLocaleTimeString(this.getLocal('lang'))}`
        }
        return `${new Date(data).toLocaleDateString("pt-BR")} ${new Date(data).toLocaleTimeString("pt-BR")}`
    }

    mascaraCPF(value) { // FUNC: mascaraCPF(value)
        // CC: Retorna a mascara do CPF.
        if(!value){
            return null
        }

        let res;
        let noformt = this.replaceSpecialChars(value)

        res = noformt.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, function( regex, argumento1, argumento2, argumento3, argumento4 ) {
            return argumento1 + '.' + argumento2 + '.' + argumento3 + '-' + argumento4;
        })

        return res; 
    }

    mascaraCNPJ(value) { // FUNC: mascaraCNPJ(value)
        // CC: Retorna a mascara do CNPJ.
        if(!value){
            return null
        }

        let res;
        let noformt = this.replaceSpecialChars(value)

        res = noformt.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, function( regex, argumento1, argumento2, argumento3, argumento4, argumento5 ) {
            return argumento1 + '.' + argumento2 + '.' + argumento3 + '/' + argumento4 + '-' + argumento5;
        })

        return res; 
    }

    validarEmail(email) { // FUNC: validarEmail(email)
        // CC: Valida o email no front end
        return (/\S+@\S+\.\S+/).test(email)
    }

    validarSenha(senha, regra  = { numeros: 1, maicusculo: 1, minusculo: 1, especiais: 1, mincaractere: 6 }) { // FUNC: validarSenha(senha, regra)
        // CC: validando a senha por regra

        let password = senha.toString()
        let rating = 0
        let numregra = 100 / Object.keys(regra).length

        const numeros    = password.toString().replace(/[^0-9]/g, '').length
        const minusculo  = password.toString().replace(/[^A-Z]/g, '').length
        const maicusculo = password.toString().replace(/[^a-z]/g, '').length
        const especiais  = password.toString().replace(/[a-zA-Z0-9]/g, '').length

        const ratingSomar = (key, value) => {
            let x = Number(numregra / regra[key]) * Number(value)
            rating += x <= numregra ? x : numregra
        }

        ratingSomar('mincaractere', password.length)
        ratingSomar('numeros', numeros)
        ratingSomar('minusculo', minusculo)
        ratingSomar('maicusculo', maicusculo)
        ratingSomar('especiais', especiais)

        let error = {
            numeros: !(numeros >= regra.numeros),
            minusculo: !(minusculo >= regra.minusculo),
            maicusculo: !(maicusculo >= regra.maicusculo),
            especiais: !(especiais >= regra.especiais),
            mincaractere: !(password.length >= regra.mincaractere),
        }

        let obj = Object.keys(error)

        for(let i = 0; i < obj.length; i++) {
            if(!error[obj[i]]){
                delete error[obj[i]]
            }
        }

        return { rating, error }

    }

    validarCPF(value) { // FUNC: validarCPF(value)
        // CC: Valida o CPF.
        const cpf    = value.replace(/\D/g, '');
        let result   = true;

        if(cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)){
            return false;
        }

        [9, 10].forEach((item) => {

            let soma = 0
            let res  = 0

            cpf.split(/(?=)/).splice(0, item)
            .forEach((e, i) => {
                soma += parseInt(e) * ((item + 2) - (i + 1));
            })

            res = soma % 11
            res = (res < 2) ? 0 : 11 - res;

            if(res != cpf.substring(item, item + 1)) {
                result = false;
            }
        })

        return result

    }

    validarCNPJ(value) { // FUNC: validarCNPJ(value)
        // CC: Valida o CNPJ.
        const cnpj    = value.replace(/\D/g, '');

        if(cnpj.toString().length != 14 || /^(\d)\1{10}$/.test(cnpj)){
            return false;
        }

        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0,tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma    = 0;
        let pos     = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2){
                pos = 9;
            }
        }

        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        
        if (resultado != digitos.charAt(0)) {
            return false;
        }
            
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma    = 0;
        pos     = tamanho - 7;

        for(let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2){
                pos = 9;
            }
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado != digitos.charAt(1)){
            return false;
        }
            
            
        return true;

    }

    getIdade(nascimento) { // FUNC: getIdade(data)
        // CC: Retorna a idade da pessoa

        let data_nascimento = new Date(nascimento + ' 00:00:00').getTime() - new Date().getTime()
        if(Math.sign(data_nascimento) != -1){
            return 0
        }
        return Math.floor(Math.ceil(Math.abs(data_nascimento) / (1000 * 3600 * 24)) / 365.25);
    }

    createdElement(type, params = { title: '', id: 'elvazio' }, value = '', state = 'body') { //FUNC: createdElement(type, params = { title: '' }, state = 'body')
        // CC: Cria um elemento HTML dinamicamente
        document.getElementById(params.id) ? document.getElementById(params.id).remove() : null
        let el = document.createElement(type)
        Object.entries(params).forEach(item => {
            el.setAttribute(item[0], item[1])
        })
        el.innerHTML = value
        document[state].appendChild(el)        
    }

    isMobile() { // FUNC: isMobile()
        // CC: Retorna true ou false se é mobile ou não
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /iOS/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
    
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    responsive() { // FUNC: responsive()
        // CC: Retorna o tamanho da tela dinamicamente.
        const self = this;
        window.addEventListener('resize', mydevice);

        function mydevice() {
            self.width = window.innerWidth;
        }

        return self.width

    }

    getSearchParams(link, parametro) { // FUNC: getSearchParams(link, parametro)
        // CC: Pesquisa o paramentro na url solicitada.
        const url = new URL(decodeURI(link))
        return url.searchParams.get(parametro);
    }

    getNomeSobrenome(nome){ // FUNC: getNomeSobrenome(nome)
        // CC: Separa o nome do sobrenome
        if(!nome) return false

        let name = nome.split(' ')
        let dados = { nome: '', sobrenome: '' }

        if(name.length > 1) {
            dados.nome = name[0]
            dados.sobrenome = name[1]
        } else [
            dados.nome = name[0]
        ]

        return dados
    }

    getDATA(data){ // FUNC:getDATA()
        if(!document.querySelectorAll(`[data-${data}]`)[0]){
            return
        }
        return document.querySelectorAll(`[data-${data}]`)[0];
    }

    setDATA(data, conteudo){ // FUNC:setDATA()
        if(!document.querySelectorAll(`[data-${data}]`)[0]){
            return
        }
        document.querySelectorAll(`[data-${data}]`)[0].innerHTML = conteudo ? conteudo : '';
    }

    customApp(dados){
    
        let config = dados

        // ADICIONA A FONTE NO PROJETO
        
        let style = `

            @import url('${config.fonteImport}');

                
            * { box-sizing: border-box;
                border-width: 0;
                border-style: solid;
                font-size: ${config.fontSize};
                font-family: ${config.fonteName} !important;
                line-height: 1.6em;
            }

            :root {
                --app-corprimaria: ${config.corprimaria};
                --app-corsecundaria: ${config.corsecundaria};
                --app-corterciaria: ${config.corterciaria};
                --app-corquarternaria: ${config.corquarternaria};
                --app-logobg-img: '${config.logoFooter}';
                --app-logo-img: '${config.logoNavBar}';
            }

            html, body { 
                font-family: ${config.fonteName} !important;
            }

            h1, h2, h3 { 
                /* border-bottom: 3px solid var(--app-corsecundaria) !important; */
                color: var(--app-corprimaria); 
                margin-bottom: 16px; 
                text-transform: uppercase; 
                font-weight: bold;
            }

            ::-webkit-scrollbar {
                width: 6px;
                height: 8px;
            }

            ::-webkit-scrollbar-track {
                background-color: var(--app-corsecundaria) !important;
            }
            
            ::-webkit-scrollbar-thumb {
                background: var(--app-corprimaria) !important;
                border-radius: 10px;
            }
            
            ::-webkit-scrollbar-thumb:hover {
                background: #b30000 !important;
            }
            
            .BotaoGradiente {
                border: solid 1px black;
                cursor: pointer;
                background: var(--app-corprimaria) !important;
            }

            .BotaoGradiente:hover {
                background: var(--app-corsecundaria) !important;
            }

            .BotaoGradiente:active {
                background:  var(--app-corprimaria)
            }

            .card-pedidos-titles {
                color: var(--app-corprimaria) !important;
            }

            .app-logobg-img:after { content:url('${config.logoFooter}'); }
            .app-logo-img:after { content:url('${config.logoNavBar}'); }

            .text-corprimaria { color: var(--app-corprimaria) !important}
            .text-corsecundaria { color: var(--app-corsecundaria) !important}
            .text-corterciaria { color: var(--app-corterciaria) !important}
            .text-corquarternaria { color: var(--app-corquarternaria) !important}
            .text-white { color: white !important }

            .bg-corprimaria { background-color: var(--app-corprimaria) !important}
            .bg-corsecundaria { background-color: var(--app-corsecundaria) !important}
            .bg-corterciaria { background-color: var(--app-corterciaria) !important}
            .bg-corquarternaria { background-color: var(--app-corquarternaria) !important}

            .app-container { width: 1024px; margin: 0px auto }

            @media screen and (max-width: 1024px) {
                .app-container { width: 100% }
            }

            @keyframes gradienteAnimater {
                from { background-position: 0% 100%; }
                to { background-position: 100% 100%; }
            }
            .app-identidadevisual { 
                height: 5px;
                background: rgb(0,255,179);
                background: linear-gradient(90deg, rgba(0,255,179,1) 0%, rgba(232,255,0,1) 25%, rgba(236,114,215,1) 55%, rgba(255,0,104,1) 80%, rgba(0,255,179,1) 100%); 
                background-size: 50% 100%;
                animation: gradienteAnimater 3s linear infinite;
            }

        `
        this.createdElement('style', { type: 'text/css' }, style, 'head')

    }

    async snackbar(mensagem, type, time = 5000) {

        this.createdElement('style', { 
            id: 'snackbarStyle',
        }, `
            .app-snackbar {
                position: fixed; 
                top: 0px; 
                width: 350px;
                padding: 10px; 
                margin: 10px; 
                left: calc(50% - 175px);
                z-index: 99999; 
                text-align: center;
                background-color: #1d1d1d;
                color: white;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            }

            .app-snackbar-add {
                animation: snackbar 1s;
            }

            .app-snackbar-remove {
                animation: snackbaremove 1s;
            }

            @keyframes snackbar {
                0% {
                    top: -60px;
                    opacity: 0;
                }
                40% {
                    top: -60px;
                    opacity: 0;
                }
                100% {
                    top: 0px;
                    opacity: 1;
                }
            }

            @keyframes snackbaremove {
                to {
                    top: -60px;
                    opacity: 0;
                }
                from {
                    top: 0px;
                    opacity: 1;
                }
            }

            .app-snackbar-error {
                background: #ff1e1e !important;
                color: rgb(255 255 255) !important;
            }

            .app-snackbar-success {
                background: #2feaa7 !important;
                color: rgb(0 0 0) !important;
            }

            .app-snackbar-warring {
                background: orange !important;
                color: black !important;
            }

        `, 'head')


        this.createdElement('div', { 
            id: 'snackbar',
            class: `app-snackbar app-snackbar-add app-snackbar-${type}`
        }, mensagem)

        await this.sleep(1/3)

        let snackbar = document.getElementById('snackbar')

        setTimeout(function() {
            snackbar.classList.remove('app-snackbar-add')
            snackbar.classList.add('app-snackbar-remove')

            setTimeout(function() {
                snackbar.remove();
            }, 900);

        }, time - 1);

    }

    modalBar(titulo, conteudo){
        this.modal.show     = true
        this.modal.titulo   = titulo
        this.modal.conteudo = conteudo
    }

    getInputs = (array) => { // FUNC: getInputs()
        // CC: Cria imputs padronizados com o projeto

        let elementos = ''
    
        array.forEach((item, index) => {
            let inputs = `
            <div class="col-span-full sm:col-span-3">
                <label required="${item.required}"><small for="${item.dataset}">${item.label}</small></label>
                <input id="${item.dataset}" type="${item.type}" placeholder="${item.placeholder}" class="${item.class} px-4 w-full rounded-full focus:ring focus:ring-opacity-75 focus:ring-violet-400 dark:text-coolGray-900 h-10" required="${item.required}" data-${item.dataset} tabindex="${index}" />
            </div>`
            elementos += inputs
        })
    
        return elementos
    }

    serialize(obj) { // FUNC: serialize(obj)
        // CC: converte objeto em json em querystring EX: ?nome=Nilson&idade=33
        var str = [];
        for (var p in obj){
            if (Object.prototype.hasOwnProperty.call(obj, p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
        return str.join("&");
    }

    removeCache(versao){ // FUNC: serialize(obj)
        // CC: Remove o cache do site anterior
        if(process.env.VUE_APP_VERSION != versao){
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName);
                });
            });
            localStorage.clear()
            sessionStorage.clear()
            console.warn('removendo o cache de arquivos!')
        }

        console.warn(`versão atual: ${process.env.VUE_APP_VERSION}`)

    }

    getInputs = (array) => { // FUNC: getInputs()
        // CC: Cria imputs padronizados com o projeto

        let elementos = ''
    
        array.forEach((item, index) => {
            let inputs = `
            <div class="col-span-full sm:col-span-3">
                <label required="${item.required}"><small for="${item.dataset}">${item.label}</small></label>
                <input id="${item.dataset}" type="${item.type}" placeholder="${item.placeholder}" class="${item.class} px-4 w-full rounded-full focus:ring focus:ring-opacity-75 focus:ring-violet-400 dark:text-coolGray-900 h-10" required="${item.required}" data-${item.dataset} tabindex="${index}" />
            </div>`
            elementos += inputs
        })
    
        return elementos
    }

    getLangWE(){
        // CC: usado apenas para converter o tipo de lang do WE.
        let lang = 'pt_BR'
        if(this.getLocal('lang')) 
            lang = this.getLocal('lang').replace('-', '_')
        return lang
    }

    async getCEP(cep) { // FUNC: getCEP(cep)
        // CC: Retorna os dados do CEP informado.
        return await new Service(`https://viacep.com.br/`).axios.get(`ws/${cep}/json/`)
    }

    async getPaises() { // FUNC: getPaises
        // CC: Retorna um obejeto com os paises
        return await new Service(`https://api.eleventickets.com/`).axios.get(`BuscaEnderecos/paises/?lang=${this.getLangWE()}`)   
    }

    async getEstados(idpais) { // FUNC: getEstados(idpais)
        // CC: Retorna um objetos com os estados.
        return await new Service(`https://api.eleventickets.com/`).axios.get(`BuscaEnderecos/estados/${idpais}?lang=${this.getLangWE()}`)
    }

    async getCidades(idestado) { // FUNC: getCidades(idestado)
        // CC: Retorna um objeto com as cidades.
        return await new Service(`https://api.eleventickets.com/`).axios.get(`BuscaEnderecos/cidades/${idestado}?lang=${this.getLangWE()}`)
    }



}

window.util = new Util()
import "../plugins/config";
import "../plugins/service/index.js";

// CONFIGURAÇÃO DE COSTUMIZAÇÃO
Vue.prototype.$util = new Vue ({
    data() {
        return {
            paises: [],
            estados: [],
            cidades: [],
            responseCEP: [],
            celmask: [
                {
                    "code": 55,
                    "mask": 11
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 34,
                    "mask": 9
                },
                {
                    "code": 93,
                    "mask": 9
                },
                {
                    "code": 355,
                    "mask": 9
                },
                {
                    "code": 213,
                    "mask": 9
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 376,
                    "mask": 6
                },
                {
                    "code": 244,
                    "mask": 9
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 54,
                    "mask": 12
                },
                {
                    "code": 374,
                    "mask": 8
                },
                {
                    "code": 297,
                    "mask": 7
                },
                {
                    "code": 61,
                    "mask": 9
                },
                {
                    "code": 43,
                    "mask": 9
                },
                {
                    "code": 994,
                    "mask": 9
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 973,
                    "mask": 8
                },
                {
                    "code": 880,
                    "mask": 10
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 375,
                    "mask": 11
                },
                {
                    "code": 32,
                    "mask": 9
                },
                {
                    "code": 501,
                    "mask": 7
                },
                {
                    "code": 229,
                    "mask": 8
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 975,
                    "mask": 8
                },
                {
                    "code": 591,
                    "mask": 8
                },
                {
                    "code": 387,
                    "mask": 8
                },
                {
                    "code": 267,
                    "mask": 8
                },
                {
                    "code": 246,
                    "mask": 7
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 673,
                    "mask": 7
                },
                {
                    "code": 359,
                    "mask": 8
                },
                {
                    "code": 226,
                    "mask": 8
                },
                {
                    "code": 257,
                    "mask": 8
                },
                {
                    "code": 855,
                    "mask": 8
                },
                {
                    "code": 237,
                    "mask": 9
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 238,
                    "mask": 7
                },
                {
                    "code": 599,
                    "mask": 7
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 236,
                    "mask": 8
                },
                {
                    "code": 235,
                    "mask": 8
                },
                {
                    "code": 56,
                    "mask": 9
                },
                {
                    "code": 86,
                    "mask": 11
                },
                {
                    "code": 61,
                    "mask": 9
                },
                {
                    "code": 61,
                    "mask": 9
                },
                {
                    "code": 57,
                    "mask": 10
                },
                {
                    "code": 269,
                    "mask": 7
                },
                {
                    "code": 243,
                    "mask": 9
                },
                {
                    "code": 242,
                    "mask": 8
                },
                {
                    "code": 682,
                    "mask": 5
                },
                {
                    "code": 506,
                    "mask": 8
                },
                {
                    "code": 225,
                    "mask": 7
                },
                {
                    "code": 385,
                    "mask": 9
                },
                {
                    "code": 53,
                    "mask": 8
                },
                {
                    "code": 599,
                    "mask": 8
                },
                {
                    "code": 357,
                    "mask": 8
                },
                {
                    "code": 420,
                    "mask": 9
                },
                {
                    "code": 45,
                    "mask": 8
                },
                {
                    "code": 253,
                    "mask": 8
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 593,
                    "mask": 9
                },
                {
                    "code": 20,
                    "mask": 10
                },
                {
                    "code": 503,
                    "mask": 8
                },
                {
                    "code": 240,
                    "mask": 9
                },
                {
                    "code": 291,
                    "mask": 7
                },
                {
                    "code": 372,
                    "mask": 8
                },
                {
                    "code": 251,
                    "mask": 9
                },
                {
                    "code": 500,
                    "mask": 5
                },
                {
                    "code": 298,
                    "mask": 6
                },
                {
                    "code": 679,
                    "mask": 7
                },
                {
                    "code": 358,
                    "mask": 9
                },
                {
                    "code": 33,
                    "mask": 9
                },
                {
                    "code": 594,
                    "mask": 9
                },
                {
                    "code": 689,
                    "mask": 8
                },
                {
                    "code": 241,
                    "mask": 7
                },
                {
                    "code": 220,
                    "mask": 7
                },
                {
                    "code": 995,
                    "mask": 9
                },
                {
                    "code": 49,
                    "mask": 11
                },
                {
                    "code": 233,
                    "mask": 9
                },
                {
                    "code": 350,
                    "mask": 8
                },
                {
                    "code": 30,
                    "mask": 10
                },
                {
                    "code": 299,
                    "mask": 6
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 590,
                    "mask": 9
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 502,
                    "mask": 8
                },
                {
                    "code": 44,
                    "mask": 10
                },
                {
                    "code": 224,
                    "mask": 9
                },
                {
                    "code": 245,
                    "mask": 9
                },
                {
                    "code": 592,
                    "mask": 7
                },
                {
                    "code": 509,
                    "mask": 8
                },
                {
                    "code": 504,
                    "mask": 8
                },
                {
                    "code": 852,
                    "mask": 8
                },
                {
                    "code": 36,
                    "mask": 10
                },
                {
                    "code": 354,
                    "mask": 7
                },
                {
                    "code": 91,
                    "mask": 10
                },
                {
                    "code": 62,
                    "mask": 9
                },
                {
                    "code": 98,
                    "mask": 10
                },
                {
                    "code": 964,
                    "mask": 10
                },
                {
                    "code": 353,
                    "mask": 9
                },
                {
                    "code": 44,
                    "mask": 10
                },
                {
                    "code": 972,
                    "mask": 9
                },
                {
                    "code": 39,
                    "mask": 10
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 81,
                    "mask": 10
                },
                {
                    "code": 44,
                    "mask": 10
                },
                {
                    "code": 962,
                    "mask": 9
                },
                {
                    "code": 7,
                    "mask": 11
                },
                {
                    "code": 254,
                    "mask": 9
                },
                {
                    "code": 686,
                    "mask": 8
                },
                {
                    "code": 383,
                    "mask": 8
                },
                {
                    "code": 965,
                    "mask": 8
                },
                {
                    "code": 996,
                    "mask": 9
                },
                {
                    "code": 856,
                    "mask": 10
                },
                {
                    "code": 371,
                    "mask": 8
                },
                {
                    "code": 961,
                    "mask": 8
                },
                {
                    "code": 266,
                    "mask": 8
                },
                {
                    "code": 231,
                    "mask": 9
                },
                {
                    "code": 218,
                    "mask": 9
                },
                {
                    "code": 423,
                    "mask": 9
                },
                {
                    "code": 370,
                    "mask": 9
                },
                {
                    "code": 352,
                    "mask": 9
                },
                {
                    "code": 853,
                    "mask": 8
                },
                {
                    "code": 389,
                    "mask": 8
                },
                {
                    "code": 261,
                    "mask": 9
                },
                {
                    "code": 265,
                    "mask": 9
                },
                {
                    "code": 60,
                    "mask": 9
                },
                {
                    "code": 960,
                    "mask": 7
                },
                {
                    "code": 223,
                    "mask": 8
                },
                {
                    "code": 356,
                    "mask": 8
                },
                {
                    "code": 692,
                    "mask": 7
                },
                {
                    "code": 596,
                    "mask": 9
                },
                {
                    "code": 222,
                    "mask": 8
                },
                {
                    "code": 230,
                    "mask": 8
                },
                {
                    "code": 262,
                    "mask": 9
                },
                {
                    "code": 52,
                    "mask": 10
                },
                {
                    "code": 691,
                    "mask": 7
                },
                {
                    "code": 373,
                    "mask": 8
                },
                {
                    "code": 377,
                    "mask": 9
                },
                {
                    "code": 976,
                    "mask": 8
                },
                {
                    "code": 382,
                    "mask": 8
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 212,
                    "mask": 9
                },
                {
                    "code": 258,
                    "mask": 9
                },
                {
                    "code": 95,
                    "mask": 8
                },
                {
                    "code": 264,
                    "mask": 9
                },
                {
                    "code": 674,
                    "mask": 7
                },
                {
                    "code": 977,
                    "mask": 10
                },
                {
                    "code": 31,
                    "mask": 9
                },
                {
                    "code": 687,
                    "mask": 6
                },
                {
                    "code": 64,
                    "mask": 9
                },
                {
                    "code": 505,
                    "mask": 8
                },
                {
                    "code": 227,
                    "mask": 8
                },
                {
                    "code": 234,
                    "mask": 10
                },
                {
                    "code": 683,
                    "mask": 7
                },
                {
                    "code": 672,
                    "mask": 6
                },
                {
                    "code": 850,
                    "mask": 10
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 47,
                    "mask": 8
                },
                {
                    "code": 968,
                    "mask": 8
                },
                {
                    "code": 92,
                    "mask": 10
                },
                {
                    "code": 680,
                    "mask": 7
                },
                {
                    "code": 970,
                    "mask": 9
                },
                {
                    "code": 507,
                    "mask": 8
                },
                {
                    "code": 675,
                    "mask": 8
                },
                {
                    "code": 595,
                    "mask": 9
                },
                {
                    "code": 51,
                    "mask": 9
                },
                {
                    "code": 63,
                    "mask": 10
                },
                {
                    "code": 48,
                    "mask": 9
                },
                {
                    "code": 351,
                    "mask": 9
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 974,
                    "mask": 8
                },
                {
                    "code": 262,
                    "mask": 9
                },
                {
                    "code": 40,
                    "mask": 9
                },
                {
                    "code": 7,
                    "mask": 11
                },
                {
                    "code": 250,
                    "mask": 9
                },
                {
                    "code": 590,
                    "mask": 9
                },
                {
                    "code": 290,
                    "mask": 5
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 590,
                    "mask": 9
                },
                {
                    "code": 508,
                    "mask": 6
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 685,
                    "mask": 7
                },
                {
                    "code": 378,
                    "mask": 8
                },
                {
                    "code": 239,
                    "mask": 7
                },
                {
                    "code": 966,
                    "mask": 9
                },
                {
                    "code": 221,
                    "mask": 9
                },
                {
                    "code": 381,
                    "mask": 9
                },
                {
                    "code": 248,
                    "mask": 7
                },
                {
                    "code": 232,
                    "mask": 8
                },
                {
                    "code": 65,
                    "mask": 8
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 421,
                    "mask": 9
                },
                {
                    "code": 386,
                    "mask": 8
                },
                {
                    "code": 677,
                    "mask": 7
                },
                {
                    "code": 252,
                    "mask": 8
                },
                {
                    "code": 27,
                    "mask": 9
                },
                {
                    "code": 82,
                    "mask": 10
                },
                {
                    "code": 211,
                    "mask": 9
                },
                {
                    "code": 94,
                    "mask": 9
                },
                {
                    "code": 249,
                    "mask": 9
                },
                {
                    "code": 597,
                    "mask": 7
                },
                {
                    "code": 47,
                    "mask": 8
                },
                {
                    "code": 268,
                    "mask": 8
                },
                {
                    "code": 46,
                    "mask": 9
                },
                {
                    "code": 41,
                    "mask": 9
                },
                {
                    "code": 963,
                    "mask": 9
                },
                {
                    "code": 992,
                    "mask": 9
                },
                {
                    "code": 886,
                    "mask": 9
                },
                {
                    "code": 255,
                    "mask": 9
                },
                {
                    "code": 66,
                    "mask": 9
                },
                {
                    "code": 670,
                    "mask": 8
                },
                {
                    "code": 228,
                    "mask": 8
                },
                {
                    "code": 690,
                    "mask": 4
                },
                {
                    "code": 676,
                    "mask": 7
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 216,
                    "mask": 8
                },
                {
                    "code": 90,
                    "mask": 10
                },
                {
                    "code": 993,
                    "mask": 9
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 688,
                    "mask": 6
                },
                {
                    "code": 1,
                    "mask": 10
                },
                {
                    "code": 256,
                    "mask": 9
                },
                {
                    "code": 380,
                    "mask": 9
                },
                {
                    "code": 971,
                    "mask": 9
                },
                {
                    "code": 44,
                    "mask": 10
                },
                {
                    "code": 598,
                    "mask": 8
                },
                {
                    "code": 998,
                    "mask": 10
                },
                {
                    "code": 678,
                    "mask": 7
                },
                {
                    "code": 39,
                    "mask": 10
                },
                {
                    "code": 58,
                    "mask": 10
                },
                {
                    "code": 84,
                    "mask": 9
                },
                {
                    "code": 681,
                    "mask": 6
                },
                {
                    "code": 212,
                    "mask": 9
                },
                {
                    "code": 967,
                    "mask": 9
                },
                {
                    "code": 260,
                    "mask": 9
                },
                {
                    "code": 263,
                    "mask": 9
                },
                {
                    "code": 358,
                    "mask": 9
                }
            ]
        }
    },
    methods: {
    
        removeHTML(str){ // TODO: removeHTML
            if(str && str.includes("<", ">")){
                str = str.replace(/<.*?>/g,'');
                str = str.replace(/&nbsp;/g,'');
                return str.replace(/\n/g,'').trim();
            }
            return str;
        },
        
        asMoney (string) { // TODO: asMoney
            var formatter = new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            });
          
            return formatter.format(string);
        },

        formatDateHour (date) { // TODO: formatDateHour
            var teste = date
            if(typeof teste == 'string' && teste !== undefined) {
                return (
                teste.substring(8, 10) +
                '/' +
                teste.substring(5, 7) +
                '/' +
                teste.substring(0, 4) +
                ' - ' +
                teste.substring(11, 16)
                )
            }

            if(typeof teste == 'object' && teste !== undefined) {
                return (
                teste[0].substring(8, 10) +
                '/' +
                teste[0].substring(5, 7) +
                '/' +
                teste[0].substring(0, 4) +
                ' - ' +
                teste[0].substring(11, 16)
                )
            }

            return
        },

        replaceSpecialChars(str){ // TODO: replaceSpecialChars
            str = str.replace(/[ÀÁÂÃÄÅ]/,"A");
            str = str.replace(/[àáâãäå]/,"a");
            str = str.replace(/[ÈÉÊË]/,"E");
            str = str.replace(/[Ç]/,"C");
            str = str.replace(/[ç]/,"c");
            // o resto
            return str.replace(/[^a-z0-9]/gi,''); 
        },

        removerCaracteres(v) { // TODO: removerCaracteres
            return Number(v.toString().replace(/\D+/g, ''));
        },

        formataCPF(value) { // TODO: formataCPF

            if(!value){
                return null
            }

            let res;
            let noformt = this.replaceSpecialChars(value)

            res = noformt.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, function( regex, argumento1, argumento2, argumento3, argumento4 ) {
                return argumento1 + '.' + argumento2 + '.' + argumento3 + '-' + argumento4;
            })

            return res; 
        }, 

        formataCNPJ(value) { // TODO: formataCNPJ

            if(!value){
                return null
            }

            let res;
            let noformt = this.replaceSpecialChars(value)

            res = noformt.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, function( regex, argumento1, argumento2, argumento3, argumento4, argumento5 ) {
                return argumento1 + '.' + argumento2 + '.' + argumento3 + '/' + argumento4 + '-' + argumento5;
            })

            return res; 
        },

        convertData(data){ // TODO: convertData
            var datearray = data.split('-');        
 
            if(this.$axios.getLocal('lang').data == 'en-US'){
                return datearray[1] + '/' + datearray[2] + '/' + datearray[0];
            }

            return datearray[2] + '/' + datearray[1] + '/' + datearray[0];
        },        

        getPaises() { // TODO: getPaises
            const self = this;
            setTimeout(() => {
                var lang = 'pt-BR'
                if(self.$axios.getLocal('lang')){
                    lang = self.$axios.getLocal('lang').data.replace('-', '_')
                }
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function() {
                    if (this.readyState == 4 && this.status == 200) {
                        self.paises = JSON.parse(this.responseText)
                    }
                };
                xhttp.open("GET", "https://api.eleventickets.com/BuscaEnderecos/paises/?lang=" + lang, true);
                xhttp.send();
            }, 1000)
            
        },

        getEstados(idpais) { // TODO: getEstados
            const self = this;
            setTimeout(() => {
                var lang = 'pt-BR'
                if(self.$axios.getLocal('lang')){
                    lang = self.$axios.getLocal('lang').data.replace('-', '_')
                }
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function() {
                    if (this.readyState == 4 && this.status == 200) {
                        self.estados = JSON.parse(this.responseText)
                        self.cidades = ""
                    }
                };
                xhttp.open("GET", `https://api.eleventickets.com/BuscaEnderecos/estados/${idpais}?lang=${lang}`, true);
                xhttp.send();
            }, 1000)
        },

        getCidades(idestado) { // TODO: getCidades
            const self = this;
            setTimeout(() => {
                var lang = 'pt-BR'
                if(self.$axios.getLocal('lang')){
                    lang = self.$axios.getLocal('lang').data.replace('-', '_')
                }
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function() {
                    if (this.readyState == 4 && this.status == 200) {
                        self.cidades = JSON.parse(this.responseText)
                    }
                };
                xhttp.open("GET", `https://api.eleventickets.com/BuscaEnderecos/cidades/${idestado}?lang=${lang}`, true);
                xhttp.send();
            }, 1000)
        },

        getCEP(cep) { // TODO: getCEP
            const self = this;
            setTimeout(() => {
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function() {
                    if (this.readyState == 4 && this.status == 200) {
                        self.responseCEP = JSON.parse(this.responseText)
                    }
                };
                xhttp.open("GET", `https://viacep.com.br/ws/${cep}/json/`, true);
                xhttp.send();
            }, 1000)
        }
        
    }
})
// Vue.prototype.$util.removeHTML();

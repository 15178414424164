import Vue from 'vue';
import "@/plugins/config.js";
import "@/plugins/service/index.js";
import '@/controllers/Carrinho.controller.js';
import '@/plugins/languagen/index.js';

Vue.prototype.$regras = new Vue({
    methods: {

        add(item, apre) {

            // if (typeof apre.dt_apre == 'object' && apre.dt_apre.length < 2) {
            //     this.$axios.modalBar('', "Informe <strong>(duas)</strong> datas para a sua visita!")
            //     return false
            // }

            if (!window.$config.carrinhoLocal) {
                this.$usuario.showModalLogin = true
                return false
            }

            let session = this.$axios.getLocal('session').data;


            return true
        },

        pagar() {
            let session = this.$axios.getLocal('session') ? this.$axios.getLocal('session').data : false;
            let carrinho = [];
            let retorno = true;

            if(session) {
                carrinho = this.$axios.getLocal('carrinho').data; 
            } else {
                carrinho = this.$axios.getLocal('local_carrinho').data;
            }

            return retorno;
        }
    }
})